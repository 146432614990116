/* ============================================================
 * zg-validate-creditCard.js
 *
 * @author: David Pocina <dpocina[at]kooomo[dot]com>
 *
 * ============================================================ */

/* globals SGL_JS_MANAGER, SGL_JS_MERCHANT_ID, SGL_JS_THEME */

(function ( $ ) {
	'use strict';

	var CARD_MAPPING = {
		bibit:     {
			amex:                      'AMEX-SSL',
			diners_club_carte_blanche: 'DINERS-SSL',
			diners_club_international: 'DINERS-SSL',
			jcb:                       'JCB-SSL',
			laser:                     'LASER-SSL', // Ireland
			visa_electron:             'VISA-SSL',
			visa:                      'VISA-SSL',
			mastercard:                'ECMC-SSL',
			discover:                  'DISCOVER-SSL', // United States
			dankort:                   'DANKORT-SSL', // Denmark
			maestro:                   'MAESTRO-SSL',
			uatp:                      'UATP-SSL'
		},
		old_realex: {
			amex:                      'AMEX',
			diners_club_carte_blanche: 'DINERS',
			diners_club_international: 'DINERS',
			jcb:                       'JCB',
			laser:                     'LASER', // Ireland
			visa_electron:             'VISA',
			visa:                      'VISA',
			mastercard:                'MC',
			maestro:                   'MC'
		},
		realex: {
			amex:                      'AMEX',
			diners_club_carte_blanche: 'DINERS',
			diners_club_international: 'DINERS',
			jcb:                       'JCB',
			laser:                     'LASER', // Ireland
			visa_electron:             'VISA',
			visa:                      'VISA',
			mastercard:                'MC',
			maestro:                   'MC'
		}
	};
	
	var BASE_CLASS_NAME = 'fa fa-fw fa-2x ';

	// className mapping
	var CLASS_MAPPING = {
		amex:                      'fa-cc-amex text-success',
		diners_club_carte_blanche: 'fa-cc-diners-club text-success',
		diners_club_international: 'fa-cc-diners-club text-success',
		jcb:                       'fa-cc-jcb text-success',
		visa_electron:             'fa-cc-visa text-success',
		visa:                      'fa-cc-visa text-success',
		mastercard:                'fa-cc-mastercard text-success',
		discover:                  'fa-cc-discover text-success'
	};

	var DEFAULT_CARD_CLASS = 'fa-credit-card text-success';
	var INVALID_CARD_CLASS = 'fa-credit-card-alt text-danger';

	// this variable will be used to store the current error msg (either number or type)
	var currentValidationMsg;


	/**
	 * Check if the payment method includes the credit card type.
	 * If the payment method doesn't exist in the CARD_MAPPING object the ccType field is not
	 * necessary for this payment method.
	 *
	 * @param {string} value - the current value of the validated element
	 * @param {HTMLElement} element - the element to be validated
	 * @returns {boolean}
	 */
	var ccValidation = function ccValidation ( value, element ) {
		var $element;
		var $cardSelector;
		var $cardIcon;
		var ccData;
		var ccType;
		var paymentMethod;
		// set default values in case the plugin is missing
		var mapped = '';
		var valid  = true;

		// check if the validateCreditCard plugin is loaded
		if ( $.fn.validateCreditCard ) {
			$element      = $( element );
			$cardSelector = $( '#ccType' );
			$cardIcon     = $( '#ccIcon' );
			ccData        = $element.validateCreditCard();
			ccType        = ccData.card_type && ccData.card_type.name || null;
			paymentMethod = $element.data( 'paymentMethod' );
			valid         = ccData.valid;

			// we show the msg about invalid card number
			if ( !valid ) {
				currentValidationMsg = __invalidNumberMsg();
			} else if ( ccType && paymentMethod && CARD_MAPPING[paymentMethod] ) {
				// Check if it is one of the recognized card types
				// If there is no mapping for the payment method all card types are valid

				mapped = CARD_MAPPING[paymentMethod][ccType] || '';
				valid  = !!mapped;

				// we show the msg about invalid card type
				if ( !valid ) {
					currentValidationMsg = __invalidTypeMsg();
				}
			}

			// show a pretty icon for the card type
			if ( valid ) {
				$cardIcon.attr( 'class', BASE_CLASS_NAME + ( CLASS_MAPPING[ccType] || DEFAULT_CARD_CLASS ) );
			} else {
				$cardIcon.attr( 'class', BASE_CLASS_NAME + INVALID_CARD_CLASS );
			}
		}

		$cardSelector.val( mapped );

		return valid;
	};


	/**
	 * Try to get the creditCard error msg from the validator plugin itself.
	 * We use it as a function so we get the updated msg if we are loading the translations
	 *
	 * @private
	 * @returns {string} - Returns the error msg
	 */
	var __invalidNumberMsg = function __invalidNumberMsg () {
		return $.validator.messages.creditcard || 'Please enter a valid credit card number.';
	};

	/**
	 * @private
	 * @returns {string} - Returns the error msg
	 */
	var __invalidTypeMsg = function __invalidTypeMsg () {
		return JS_TRANSLATIONS['secureCC.invalidCardType'];
	};

	/**
	 *
	 * @returns {string} - Returns the error msg
	 */
	var ccValidationMsg = function ccValidationMsg () {
		return currentValidationMsg || __invalidNumberMsg();
	};

	$( function () {
		if ( SGL_JS_MANAGER === 'secure' ) {
			// Get validator plugin
			$.getScript( '/themes/' + SGL_JS_MERCHANT_ID + '/' + SGL_JS_THEME + '/js/lib/jquery.creditcardvalidator.js' );

			// Initialize validation
			$.validator.addMethod( 'ccValidation', ccValidation, ccValidationMsg );
			$.validator.addClassRules( 'ccValidation', { required: true, ccValidation: true } );
		}
	} );
})( jQuery );
