// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here
	/*
	//
	// Instantiate the Bootstrap carousel
		$('.multi-item-carousel').carousel({
			interval: 3000
		});

		// for every slide in carousel, copy the next slide's item in the slide.
		// Do the same for the next, next item.
		$('.multi-item-carousel .item').each(function(){
			var next = $(this).next();
			if (!next.length) {
				next = $(this).siblings(':first');
			}
			next.children(':first-child').clone().appendTo($(this));

			if (next.next().length>0) {
				next.next().children(':first-child').clone().appendTo($(this));
			} else {
				$(this).siblings(':first').children(':first-child').clone().appendTo($(this));
			}
		});
	//
	*/
	
	// Readmore
	$( function () {
			$(".btnRead>a").click(function(){
				$(".btnRead>a").toggle();
			});
			$("a.btnOff").click(function(){
				$(".readmore").css("height", "40px");
			});
			$("a.btnOn").click(function(){
				$(".readmore").css("height", "auto");
			});
	} );
	
	/* new version*/
	$( function () {
		var countChar = $('.read_more').data('target');
		//console.log(countChar);
		if(countChar > "250"){
			var h_box='65px';
				if(window.IS_MOBILE){
					h_box='66px';
				}
			$('.read_more').css('height', h_box);
			$('a.btn_off').click(function(){
				$(this).parent('.read_more').css('height', h_box);
				$(this).toggle();
				$(this).parent('.read_more').find('a.btn_on').toggle();
			});
			$('a.btn_on').click(function(){
				$(this).parent('.read_more').css('height', 'auto');
				$(this).toggle();
				$(this).parent('.read_more').find('a.btn_off').toggle();
			});
		}
	} );
	

	// calculate image height in the related products box
	/*$(window).load(function() {	
		if($('#viewCarousel .item img').length){
			if(!window.IS_MOBILE && !window.IS_TABLET){
				var heightImg = $('#viewCarousel .item img').innerHeight();
				$('.you-may-also-like .leftCnt').css('height', heightImg);
			}
			$(document).on('click','.you-may-also-like .carousel-indicators li', function () {
				$('.you-may-also-like .carousel-indicators li').removeClass('active');
				$(this).addClass('active');
			});
		}
		
	});*/
	
	// focus on newsletter modal input type
	//$( function () {
		//$(document).on('click','.btn-nl-footer', function () {	
			//console.log('open');
		//})
	//})
	
	// header mobile icon bar transformation
	$( function () {
		$(document).on('click','.mobile-toggle', function () {	
			if ($(".mobile-toggle").hasClass("is-open")){
					$(".mobile-toggle").removeClass("is-open");
					$("body").removeClass("stop-scrolling");
			} else {
					$("#elasticPanel").removeClass("open");
					$(".mobile-toggle").addClass("is-open");
					$("body").addClass("stop-scrolling");
			}
		})
	})
	
	// minicart dropdown btn-close
	$( function () {
		$(document).on('click','.list-items-container .btn-close', function () {	
			$('[data-zg-role="cart"]').removeClass('open');
			//console.log('close');
		})
	})

	// fake dropdown and check for active filter
	$( function () {
		// if data-zg-action reset is active ( no filter on )
		$(document).on('click','.dropBtnSort', function (e) {
			if ($("#zgDropdownSort").hasClass('show')){
				$("#zgDropdownSort").removeClass('show');
				$('.dropBtnSort').removeClass('active');
			} else {
				$("#zgDropdown").removeClass('show');
				$('.dropBtn').removeClass('active');
				$('.dropBtnSort').addClass('active');
				$("#zgDropdownSort").addClass('show');
			}
		});
	} );
	
	// fake dropdown and check for active filter
	$( function () {
		// if data-zg-action reset is active ( no filter on )
		$(document).on('click','.dropBtn', function (e) {
			if ($("#zgDropdown").hasClass('show')){
				$("#zgDropdown").removeClass('show');
				$('.dropBtn').removeClass('active');
			} else {
				$("#zgDropdownSort").removeClass('show');
				$('.dropBtnSort').removeClass('active');
				$('.dropBtn').addClass('active');
				$("#zgDropdown").addClass('show');
			}
			
			// if data-zg-action reset is active ( no filter on )
			if (($('[data-zg-action="reset"].active').length) == 2){
				$('.dropBtn').removeClass('filterOn');
			} else {
				$('.dropBtn').addClass('filterOn');
			}
		});
	} );
	
	// homepage box multilink
	$('.caption div a').hover(
		function() {
			var idImage = $(this).data('target');
			$('.imageBox').removeClass('active');
    	$('#' + idImage).addClass('active');
  	}, function() {
    	$('.imageBox').removeClass('active');
    	$('#img1').addClass('active');
  	}
	)
	
	// Focus on first input inside a modal
	$('#newsletterModal').on('shown.bs.modal', function() {
  	$(this).find('input:first').focus();
	});
	
	$( function () {
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		// Carousel Settings - Home
		$( '.carousel' ).carousel( {} );

        // SWIPE CAROUSEL
        var $carousel = $( '.carousel' );
        if ( $carousel.length ) {
            $carousel.swiperight( function () {
                $( this ).carousel( 'prev' );
            } );
            $carousel.swipeleft( function () {
                $( this ).carousel( 'next' );
            } );
        }
	} );
	
	// register page
	if ( $('[data-zg-role="register-area"]').length ) {
		//$('[data-zg-role="register-area"]').hide();
		//$('[data-zg-role="guest-area"]').hide();
		$(document).on("click",'[data-zg-role="register-btn"]',function(e) {
				e.preventDefault();
				$('[data-zg-role="login-area"]').hide();
				$('[data-zg-role="register-area"]').show();
		});

		$(document).on("click",'[data-zg-role="guest-btn"]',function(e) {
				e.preventDefault();
				$('[data-zg-role="login-area"]').hide();
				$('[data-zg-role="guest-area"]').show();
		});

		$(document).on("click",'[data-zg-role="back-register"]',function(e) {
				e.preventDefault();
				$('[data-zg-role="login-area"]').show();
				$('[data-zg-role="register-area"]').hide();
		});

		$(document).on("click",'[data-zg-role="back-guest"]',function(e) {
				e.preventDefault();
				$('[data-zg-role="login-area"]').show();
				$('[data-zg-role="guest-area"]').hide();
		});
	}

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							//if is the first visible I also show the function definition
							/*if(count == 0){
								console.log($(this).find('a'));
							}*/

							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});
	

	// ===== Scroll slowly to the target ====
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
				href = $(this).attr("href"),
				offsetInserted = $(this).data('zg-offset'),
				offset = $(target).offset().top;

			if( offsetInserted ){
				offset -= offsetInserted;
			}
			$('html, body').animate({scrollTop: offset}, 1000);
			window.location.hash = href;
		});
	});

	// ===== Redirecting to the "Messages" tab in the My Acoount page when the user clicks on "View my messages" ====
	if ( SGL_JS_MANAGER === 'account' && window.location.hash === '#tickets' ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}


	// ===== Fading out the bouncing arrow for the Home Page slider ==≠≠
	/*$( window ).scroll( function(){
		$("#return-to-top").css("opacity", 1 - $(window).scrollTop() / 250);
		//250 is fade pixels
	});*/

	/*$( '[data-zg-role="btn-search"]' ).click( function(){
		$( '#searchContainer').toggle( '200' );
	});*/


	// ===== Scroll to Top ====
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click(function() {
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});
	
	/*
	// =====  Stiky header ====
	*/
	//STICKY HEADER CHANGE BG
	var $stickyHeader=$('#site_header');
	var main_banner=false;
	
	// SET BANNER 100% HEIGHT
	function setImageBanner(){
		var $docHeight =  $( window ).height();
		if(!window.IS_MOBILE && !window.IS_TABLET){
			var $docHeight =  $( window ).height();
		}else{
			var $docHeight =  ($( window ).height()) - 30;
		}
		
		if(!window.IS_TABLET){
		$('.zg-component-slider').css('height',$docHeight);
		}
		
	}
	if($('.zg-component-slider').length){
		setImageBanner();
		main_banner=true;
	}
	function mainBanner(){
		if(main_banner){			
			var sticky = $('.zg-component-slider').height();//+$stickyHeader.height();
			var headerTheme = ZG_CONFIG.headerTheme;
			if (window.pageYOffset > sticky) {
					$stickyHeader.removeClass( headerTheme );
			}else{
					$stickyHeader.addClass( headerTheme );
			}
		}
	}
	$(window).scroll(function() {
		mainBanner();
	});
	$( window ).resize(function() {
		if($('.zg-component-slider').length){
			setImageBanner();
		}
	});
	$(document).ready(function(){
			mainBanner();
	});
	
	/*
	// =====  Stiky header ====
	$(window).scroll(function() {
		var scrollPos = $(document).scrollTop();
    if (scrollPos > 1){  
      $('body').addClass("sticky");
    }
    else{
      $('body').removeClass("sticky");
    }	
	}); 
	
	var lastScrollTop;
	
	$(window).scroll(function() {
		var st = $(this).scrollTop();
		if (st > lastScrollTop){
				if (st > 100){  
					$('body').addClass("sticky-notification");
				}
				else{
					$('body').removeClass("sticky-notification");
				}	
		} else {
				if (st > 250){  
					$('body').addClass("sticky-notification");
				}
				else{
					$('body').removeClass("sticky-notification");
				}*
				$('body').removeClass("sticky-notification");
		}
		lastScrollTop = st;

	}); 
	*/
	

})( jQuery );

$( function () {
	$( '[data-zg-role="change-qty-xs"]' ).click( function( e ){
		var id = $( this ).data('id');
		$( '[data-product-id="' + id + '"]' ).find('.cart-qty-selector').removeClass( 'hidden-xs hidden-sm' );
	});
} );	

$( function () {
	$( '[data-zg-role="change-dim"]' ).on( "click", function(e) {
		e.preventDefault();
		$( '[data-zg-role="change-dim"]' ).removeClass('active');
		$( this ).addClass('active');
		var number = $( this ).data('number');
		$('.product-preview').removeClass('col-md-3').removeClass('col-md-4');
		$('.product-preview').addClass('col-md-' + number);
		writeCookie('catcol',number,6000);
		CATCOL = number;
		$( '.featured-prod' ).hide();
		
	});
	
	$( document ).on( 'click', '[data-zg-role="pagination-sort"] a', function ( e ) {
		var text = $(this).text();
		$('[data-zg-role="sort-title"]').html(text +"<label>+</label><label>-</label>");
	} );
} );	


$( function () {
	$( '[data-zg-role="btn-cat-xs"]' ).on( "click", function(e) {
		var element = $( this ).data('element');
		if( $('[data-type = "' + element + '"]').hasClass('collapsed')){
		}else{
			$('[data-type = "' + element + '"]').trigger('click');
		}
	});
} );	






function writeCookie(nameCookie,valCookie,timeCookie)
{
  var scadenza = new Date();
  var adesso = new Date();
  scadenza.setTime(adesso.getTime() + (parseInt(timeCookie) * 60000));
  document.cookie = nameCookie + '=' + escape(valCookie) + '; expires=' + scadenza.toGMTString() + '; path=/';
}

/* CLOSE MODAL AFTER ADD TO CART */
/*$(document).on('zg.product.addedToCart',function(){
	if($('#quickbuy').data('bs.modal').isShown == true){
		$('#quickbuy').modal('hide');
	}		
});*/



/* SIZE CHART FROM CMS*/
(function ($) {
	 var root = this;
	$(document).on('click','[data-zg-role="load-size-chart"]',function(e){
		e.preventDefault();
		var idc = $(this).parents('[data-sizechart-id]').attr('data-sizechart-id');
		var idp = $(this).parents('[data-sizechart-id]').attr('data-id');
		//console.log(idc);
		var data = $( this ).data();
		var url = window.makeUrl( {
					module: 'eshop',
					manager: 'cms',
					action: 'list',
					type: 'block_sizeChart',
					//sizechart_id:data.sizechartCategories,
					sizechart_id:idc,
					product_id:idp,
					master: 'master_blank',//'sizechart_id[`18846543`]',
					skip_redir: '1'
			} );
		
		root.loadAjaxFile(null, data.ajaxContainer, url);
	});
}).call(this, jQuery);

/**
 * jQuery Smooth Scroll to Anchor
 */

$(document).scroll('#help-area',function(event) {
		//console.log('scrolling', event);    
		//console.log('scrolling', event.originalEvent);
	
}, true /*Capture event*/);



if($('.block-category-info').length){
	$(window).scroll(function(){
		$('.block-category-info').each(function(){
			//if ($(this).offset().top < $(window).scrollTop()) {
				var difference = $(window).scrollTop();
				var half = (difference / 2) + 'px';
				$(this).find('img').css('top', half);
			//} else {
				//$(this).find('img').css('top', '0');
			//}
		});
	});
};


if($('.team-banner').length){
	$(window).scroll(function(){
		$('.team-banner').each(function(){
			//if ($(this).offset().top < $(window).scrollTop()) {
				var difference = $(window).scrollTop();
				var half = -(difference / 3) + 'px';
				var halflogo = (difference / 2) + 'px';
				$(this).find('img.img-responsive').css('top', half);
				//$(this).find('img.logo').css('top', halflogo);
			
			
			//} else {
				//$(this).find('img').css('top', '0');
			//}
		});
	});
};

/*
	if($('.carousel-fade').length){
		setTimeout(function(){ $('#carousel-610169').carousel('next'); $('#carousel-610169').removeClass('hidden'); }, 1);
	};
*/

//BUTTON NUMBER
	if($('.btn-number').length != 0){
		$('.btn-number').click(function(e){
			$('.message-number').addClass('hidden');
			e.preventDefault();
			var fieldName = $(this).data('target');
			var type      = $(this).data('type');
			var input = $("input[name='"+fieldName+"']");
			var currentVal = parseInt(input.val());
			//console.log(input);
			if (!isNaN(currentVal)) {
					if(type == 'minus') {

							if(currentVal > input.attr('min')) {
									input.val(currentVal - 1).change();
							} 

					} else if(type == 'plus') {

							if(currentVal < input.attr('max')) {
									input.val(currentVal + 1).change();
							}else{
								//console.log('not disponibile');
								$('.message-number').removeClass('hidden');
							}

					}
			} else {
					input.val(0);
			}
		});
		$('.input-number').focusin(function(){
			$(this).data('oldValue', $(this).val());
		});
		$('.input-number').change(function() {
				//console.log("changed");
				var minValue =  parseInt($(this).attr('min'));
				var maxValue =  parseInt($(this).attr('max'));
				var valueCurrent = parseInt($(this).val());

				var name = $(this).attr('name');
				if(valueCurrent >= minValue) {
						//$(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
				} else {
						$(this).val($(this).data('oldValue'));
				}
				if(valueCurrent <= maxValue) {
						//$(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
				} else {
						$(this).val($(this).data('oldValue'));
				}


		});
		$(".input-number").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
             // Allow: Ctrl+A
            (e.keyCode == 65 && e.ctrlKey === true) || 
             // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
                 // let it happen, don't do anything
                 return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
	}















$( function () {
	
	$( '[data-zg-role="slickslider"]').each(function( index ) {
		
		var slidesToShow = $( this ).attr('data-element');
		if ( slidesToShow ) {
			parseInt(slidesToShow);
		}else{
			slidesToShow = 10;
		}
		
		var slidesToShowMobile = $( this ).attr('data-elementmobile');
		if ( slidesToShowMobile ) {
			parseInt(slidesToShowMobile);
		}else{
			slidesToShowMobile = 4;
		}
		
		$( this ).slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1
          }
        },
        {
           breakpoint: 767,
           settings: {
              arrows: false,
              slidesToShow: slidesToShowMobile,
              slidesToScroll: 1
           }
        }]
    });
	});
	
	$( '[data-zg-role="slickslider"]').removeClass('hidden');
	
	

	
	
	
} );




$(document).ready(function() {	
	$("#label_promo_code_what_is").hover(function(){
		$("#promo_code_text").fadeIn();
   },function(){
    $("#promo_code_text").fadeOut();
   }
  );        
		
	$("#label_promo_code_what_is").click( function(){$("#promo_code_text").toggle();} );
	
});



$(document).ready(function() {
	if(window.IS_MOBILE || window.IS_TABLET){
	}else{
		if( $(".central_image").length ){
			var imgWidth = $(".central_image img").width();
			$(".central_image .container-fluid").width( imgWidth ); 
			$( window ).resize(function() {
				var imgWidth = $(".central_image img").width();
				$(".central_image .container-fluid").width( imgWidth ); 
			});
		}
	}
	
	/*header cart image control*/
	$('[data-zg-role="cart-items-count"]').on('DOMSubtreeModified', function() {
		var c=parseInt($('[data-zg-role="cart-items-count"]').text());
		$par=$(this).parent('.cart-toggle');
		/*if( c >= 1 ){			
			$par.find('svg:nth-child(2)').removeClass('hidden');
			$par.find('svg:nth-child(1)').addClass('hidden');
		}else{
			$par.find('svg:nth-child(1)').removeClass('hidden');
			$par.find('svg:nth-child(2)').addClass('hidden');
		}*/
	
	});
	
});


/*
(function ($) {
	$('.multi-item-carousel').each(function(){
		console.log('totalItems');
		var $t=$(this);
		var $inner=$t.find('.carousel-inner');
		if($t.find('.item').length >=2){
			
			var itemsXItem=$t.find('.item:first').find('.item-content').length;
			var totalItems=$t.find('.item-content').length;
			var $items = new Array(totalItems);
			$t.find('.item-content').each(function(index){
				$items[index]=$(this);
			});		
			$inner.find('.item').remove();
			console.log('totalItems');
			console.log(totalItems);
			for(var i = 0; i < totalItems; i++){
				
				var $item=$('<div class="item"></div>');
				var currId=i;
				for(var j=0; j<itemsXItem;j++){				
					console.log(currId);
					$item.append($items[currId]);
					if(currId<totalItems){						
						currId++;
					}else{
						currId=0;
					}
				}
				$inner.append($item);
			}
			
			
		}
	});
});
*/

		


// HOME ANIMATIONS
function macchinaDaScrivere(id_campo, testo, velocita, posizione){
    var lunghezza = testo.length;
    posizione = posizione || 0;
    if(posizione<lunghezza){
        var carattere = testo.substring(posizione,posizione+1);
        document.getElementById(id_campo).innerHTML=document.getElementById(id_campo).innerHTML.substring(0,posizione)+carattere;      
        setTimeout('macchinaDaScrivere("'+id_campo+'","'+testo+'",'+velocita+','+(posizione+1)+');',velocita);
    }else{
        document.getElementById(id_campo).innerHTML=document.getElementById(id_campo).innerHTML.substring(0,lunghezza);
				$('#' + id_campo).toggleClass('white_underscore');  
				setInterval(function(){ 
					$('#' + id_campo).toggleClass('white_underscore');  
					setTimeout(function(){
						$('#' + id_campo).toggleClass('white_underscore');  
					},400);
				},800);
			
			
		}        
}

var $animation_elements = $('.full_with_animation');
var $animation_elements_text = $('[data-zg-role="text-animation"]');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);
 
  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);
 
    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
      	setTimeout(function(){ $element.addClass('in-view'); }, 2000);
    } else {
				//setTimeout(function(){ $element.removeClass('in-view'); }, 2000);
    }
  });
	
	
	
	
	
	$.each($animation_elements_text, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);
 
    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
      	
			if ( $element.text() == ''){
					var testo = $element.data('text');
					var id_testo = $element.attr('id');
					//console.log(testo);
    			testo = testo.replace(/\s+/g," ");
    			macchinaDaScrivere(id_testo,testo,200);  
			}
				
				
    } else {
			 //$element.text('').removeClass('white_underscore underscore').addClass('underscore');	
    }
  });
	
	
	
	
	
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// END HOME ANIMATIONS



$('.menuCnt ul.nav li.dropdown').hover(function() {
	$(this).addClass('open');
  $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
}, function() {
	$(this).removeClass('open');
  $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
});

/*$('#prodCarousel').carousel({
  interval: 10000
})
*/

/*
$('#prodCarousel .item').each(function(){
  var next = $(this).next();
  if (!next.length) {
    next = $(this).siblings(':first');
  }
  next.children(':first-child').clone().appendTo($(this));
  
  
});
*/
/*Old Carousel
$('.carousel[data-type="multi"] .item').each(function(){
  var next = $(this).next();
  if (!next.length) {
    next = $(this).siblings(':first');
  }
  next.children(':first-child').clone().appendTo($(this));
	/* Do not uncomment thins when restore old carousel
  for (var i=0;i<2;i++) {
    next=next.next();
    if (!next.length) {
    	next = $(this).siblings(':first');
  	}
    
    next.children(':first-child').clone().appendTo($(this));
  }*/
  /*
});
*/

$('.slider-products').slick({
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: '<i class="fas fa-chevron-circle-right slick-next"></i>',
  prevArrow: '<i class="fas fa-chevron-circle-left slick-prev"></i>',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
		infinite: true,
      }
    }
  ]
});

$(document).ready(function() {	
	if($('#registeruser_pwd')){
			$(document).on('keydown', '#registeruser_pwd', function(e) { 
				var keyCode = e.keyCode || e.which; 
				//alert(keyCode);
				if (keyCode == 9) { 
					e.preventDefault(); 
					// call custom function here
					$('#registeruser_pwd_confirm').focus();
					
				} 
			});
	}
});
$(document).on('click','[data-zg-role="fake-add-to-cart"]',function(){
	$('#option-unselected').removeClass('hidden');
});
/*QUICKBUY GALLERY*/
$(document).on('click','#quickbuy .ar-row.right-arrow',function(){
	if($('.quickbuy .thumbnail.active').parent('.gallery-item').next()){
		$('.quickbuy .thumbnail.active').parent('.gallery-item').next().find('a').click();
	}
});
$(document).on('click','#quickbuy .ar-row.left-arrow',function(){
	if($('.quickbuy .thumbnail.active').parent('.gallery-item').prev()){
		$('.quickbuy .thumbnail.active').parent('.gallery-item').prev().find('a').click();
	}
});
$(document).on('click','.block_carousel_fit .ar-row.left-arrow',function(){
	var w=$(this).parents('.block_carousel_fit').find('.fit_box').width();
	var scroll=$(this).parents('.block_carousel_fit').find('.box-container').scrollLeft()-w;
	$(this).parents('.block_carousel_fit').find('.box-container').animate({scrollLeft: scroll}, 800);
});
$(document).on('click','.block_carousel_fit .ar-row.right-arrow',function(){
	var w=$(this).parents('.block_carousel_fit').find('.fit_box').width();
	var scroll=$(this).parents('.block_carousel_fit').find('.box-container').scrollLeft()+w;
	$(this).parents('.block_carousel_fit').find('.box-container').animate({scrollLeft: scroll}, 800);
});

/*CUSTOM DREAM BAG CLICK*/

$(document).on('click','[data-zg-role="option-selector"] .exploded.disabled',function(e){
	e.preventDefault();e.stopPropagation();
	//console.log("show dream");
	if (IS_MOBILE){
		$('.optionCnt .close').trigger('click');
	}
	$('.dreambag').removeClass('hidden');
});
$(document).on('click','[data-zg-role="option-selector"] .exploded:not(.disabled)',function(){
		//console.log('hide dream');
		$('.dreambag').addClass('hidden');
	});
	
$(document).on('click','.dreambag .close',function(){
		$('.dreambag').addClass('hidden');
});


$(document).on('click','.dreambag .btn',function(e){
	 var isValid = $(e.target).parents('form').valid();
    if(isValid) {
			$('.dreambag').addClass('hidden');
		}
});
$("#quickbuy").on('shown.bs.modal', function(){
   $(document).on('click','[data-zg-role="option-selector"] .exploded.disabled',function(e){
		e.preventDefault();e.stopPropagation();
		//console.log("show dream");
		if (IS_MOBILE){
			$('.optionCnt .close').trigger('click');
		}
		$('.dreambag').removeClass('hidden');
	});
	 $(document).on('click','[data-zg-role="option-selector"] .exploded:not(.disabled)',function(){
		$('.dreambag').addClass('hidden');
	});
	$(document).on('click','.dreambag .close',function(){
		$('.dreambag').addClass('hidden');
	});
	$(document).on('click','.dreambag .btn',function(e){
		var isValid = $(e.target).parents('form').valid();
			if(isValid) {
				$('.dreambag').addClass('hidden');
			}
	});
});
//CUSTOM PRODUCT OPTIONS FOR MOBILE
if (IS_MOBILE){
	$('.mobile-absolute-options .close').on('click',function(){
		$('.mobile-absolute-options').addClass('hidden');
		$('.mobile-cart-button').addClass('overlapped');
	});
	$('.mobile-cart-button.overlapped,.mobile-cart-button.overlapped+[data-zg-role="fake-add-to-cart"]').on('touchend click',function(e){
		if($('.mobile-cart-button').hasClass('overlapped')){
			e.stopPropagation();
			e.preventDefault();	
			//console.log('overlap click');
			$('.mobile-absolute-options').removeClass('hidden');
			$('.mobile-cart-button').removeClass('overlapped');
		}
	});
}

$(document).ready(function() {	
	
			$('.carousel-showmanymoveone .item').each(function(){								
				var itemToClone = $(this);
				for (var i=1;i<3;i++) {
					itemToClone = itemToClone.next();
					// wrap around if at end of item collection
					if (!itemToClone.length) {
						itemToClone = $(this).siblings(':first');
					}
					// grab item, clone, add marker class, add to collection
					itemToClone.children(':first-child').clone()
						.addClass("cloneditem-"+(i))
						.appendTo($(this));
				}
			});	
	
	
		$('.carousel-showmanymoveone-2items .item').each(function(){						
			//console.log($(this).parent().parent().attr('data-items'));
				var itemToClone = $(this);
				for (var i=1;i<2;i++) {
					itemToClone = itemToClone.next();
					// wrap around if at end of item collection
					if (!itemToClone.length) {
						itemToClone = $(this).siblings(':first');
					}
					// grab item, clone, add marker class, add to collection
					itemToClone.children(':first-child').clone()
						.addClass("cloneditem-"+(i))
						.appendTo($(this));
				}
			});	
	
			$('.carousel-showmanymoveone-3items .item').each(function(){						
			//console.log($(this).parent().parent().attr('data-items'));
				var itemToClone = $(this);
				for (var i=1;i<3;i++) {
					itemToClone = itemToClone.next();
					// wrap around if at end of item collection
					if (!itemToClone.length) {
						itemToClone = $(this).siblings(':first');
					}
					// grab item, clone, add marker class, add to collection
					itemToClone.children(':first-child').clone()
						.addClass("cloneditem-"+(i))
						.appendTo($(this));
				}
			});	
	
	
});
$('.country-letter-search >a').on('click',function(e){
	e.stopPropagation();
	e.preventDefault();
	
	$('.country-letter-search').parent('ul').find('li[data-letter]').removeClass('hidden');
	if($(this).hasClass('active')){
		$(this).removeClass('active');
	}else{
		$('.country-letter-search >a').removeClass('active');
		var letter=$(this).html();
		$(this).addClass('active');
		$('.country-letter-search').parent('ul').find('li[data-letter]').each(function(){
			if(letter == $(this).attr('data-letter')){
				$(this).removeClass('hidden');
			}else{
				$(this).addClass('hidden');
			}		
		});
	}
});

//Function to validate the mandatory 16 digits to be added to the Fiscal Code input in the Registration form
/*$( '[data-zg-role="validate-digits"]' ).on('input', function(){
	var $span = $( this ).siblings( 'span' );
	if( $( this ).val().length == 16 ){
		$span.addClass( 'hidden' )
	}
	else {
		$span.removeClass( 'hidden' )
	}
});
*/

/*
$( function () { 
	// open modal NL in home
	if($('body.page_home')){
		if(zgReadCookie( 'nl_readed' ) !=1){
			zgCreateCookie('nl_readed', '1', 7);
			$('.btn-nl-footer').trigger('click');
		}
	}
	
});
*/

/*FISCAL CODE FORM MANDATORY
$(document).on('change paste keyup','#fiscal_code',function(){
	//console.log(controllaCF($(this).val()));
	var is_valid=controllaCF($(this).val());
	if(is_valid){
		$(this).next('.alert-danger').addClass('hidden');
		$(this).parents('form').find('[type="submit"]').prop('disabled', false);
	}else{
		$(this).next('.alert-danger').removeClass('hidden');
		$(this).parents('form').find('[type="submit"]').prop('disabled', true);
	}
});
$( "#address_form_modal" ).on('shown.bs.modal', function(){
	var is_valid=controllaCF($(this).find('#fiscal_code').val());
	if(is_valid){
		console.log('open modal valid');
		$(this).find('#fiscal_code').next('.alert-danger').addClass('hidden');
		$(this).find('form').find('[type="submit"]').prop('disabled', false);
	}else{
		console.log('open modal not valid');
		$(this).find('#fiscal_code').next('.alert-danger').removeClass('hidden');
		$(this).find('form').find('[type="submit"]').prop('disabled', true);
	}
});
(function(){
	$('#fiscal_code').on('change paste keyup',function(){
		var is_valid=controllaCF($(this).val());
		if(is_valid){
			$(this).next('.alert-danger').addClass('hidden');
			$(this).parents('form').find('[type="submit"]').prop('disabled', false);
		}else{
			$(this).next('.alert-danger').removeClass('hidden');
			$(this).parents('form').find('[type="submit"]').prop('disabled', true);
		}
	});
});

function controllaCF(val)
{
  // Definisco un pattern per il confronto
  var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

  // utilizzo il metodo search per verificare che il valore inserito nel campo
  // di input rispetti la stringa di verifica (pattern)
  if (val.search(pattern) == -1)
  {
    // In caso di errore stampo un avviso e pulisco il campo...
    return false;
  }else{
     // ...in caso contrario stampo un avviso di successo!
     return true;
  }
}
*/
$(document).on('keydown', '[data-invoicing="fiscal-code"]', function(e) { 
var value =  $(this).val();
	//only allow a-z, A-Z, digits 0-9 and comma, with only 1 consecutive comma ...
	if (!e.key.match(/[a-zA-Z0-9]/)) {
		e.preventDefault();  
	}
});



	// minicart dropdown 
	/*$( function () {
		$(document).on('click','.mobile-cart-button', function () {	
			$('.dropdown-cart').addClass('show');
			console.log('click in');
		})
	})
	
	$( function () {
		$(document).on('click','.list-items-container .btn-close', function () {	
			$('.list-items-container .dropdown-cart').removeClass('show');
			console.log('click out');
		})
	})*/