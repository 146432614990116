/* =============================================================
 * bootstrap-typeahead.js v2.3.1
 * http://twitter.github.com/bootstrap/javascript.html#typeahead
 * =============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License. 
 * =============================================================
 * Modifications by Paul Warelis
 * Updated to bootstrap v2.3.1 by David Pocina
 * ============================================================ */


(function($){

	"use strict"; // jshint ;_;

	/* TYPEAHEAD PUBLIC CLASS DEFINITION
	 * ================================= */

	var Typeahead = function (element, options) {
		this.$element = $(element)
		this.options = $.extend({}, $.fn.typeahead.defaults, options)

		this.select = this.options.select || this.select
		this.show = this.options.show || this.show
		this.hide = this.options.hide || this.hide
		this.render = this.options.render || this.render
		this.keyup = this.options.keyup || this.keyup
		this.blur = this.options.blur || this.blur
		this.click = this.options.click || this.click
		this.matcher = this.options.matcher || this.matcher
		this.sorter = this.options.sorter || this.sorter
		this.highlighter = this.options.highlighter || this.highlighter
		this.updater = this.options.updater || this.updater
		this.$menu = $(this.options.menu)
		if (this.options.ajax) {
			var ajax = this.options.ajax;
			if (typeof ajax == "string") {
				ajax = { url:ajax };
			}
			this.ajax = {
				url : ajax.url,
				timeout : ajax.timeout || 300,
				method: ajax.method || "post",
				triggerLength : ajax.triggerLength || 3,
				loadingClass : ajax.loadingClass || null,
				dType : ajax.dType || null,
				displayField : ajax.displayField || null,
				loadProductPageBy : ajax.loadProductPageBy || null,
				preDispatch : ajax.preDispatch || null,
				preProcess : ajax.preProcess || null
			}
			this.query = "";
		} else {
			this.source = this.options.source
			this.ajax = null;
		}
		this.shown = false
		this.listen()
	}

	Typeahead.prototype = {

		constructor: Typeahead

	,	select: function () {
			var val = this.$menu.find('.active').attr('data-value')
				if (typeof val !== 'undefined') {
					this.$element
							.val(this.updater(val))
							.change()
							
					if (this.ajax && this.ajax.loadProductPageBy!=null){
							var id = this.$menu.find('.active').attr('data-id');
							var type = this.$menu.find('.active').attr('data-type');
							if( id > 0 ){
								this.$element.attr('data-id',id);
								this.$element.attr('data-type',type);
								
								if(type == 'product'){
									this.$element.attr('data-value',this.$menu.find('.active').attr('data-value'));
								}
								// if ($("#search-product_id").length>0 && $("#search-product_name").length>0) {
								// 		$("#search-product_id").val(productId);
								// 		var n=val.indexOf(" - ");
								// 		$("#search-product_name").val(val.substring(0,n));
								// 		$("#product-finder").submit();
								// }
							} else {
								this.$element.val(this.query)
							}
							$("#product-finder").submit();
					}
				}
			return this.hide()
		}

	,	updater: function (item) {
			return item
		}
	
	,	show: function () {
			//var pos = $.extend({}, this.$element.position(), {
			//	height: this.$element[0].offsetHeight
			//})
			
			this.$menu
				.insertAfter(this.$element)
				//.css({
				//	top: pos.top + pos.height
				//,	left: pos.left
				//})
				.show()
			
			this.shown = true
			return this
		}

	,	hide: function () {
			this.$menu.hide()
			this.shown = false
			return this
		}

	,	ajaxLookup: function () {
			var query = this.$element.val();
			
			if (query == this.query)
				return this;
	
			// Query changed
			this.query = query
	
			// Cancel last timer if set
			if (this.ajax.timerId) {
				clearTimeout(this.ajax.timerId);
				this.ajax.timerId = null;
			}
			
			if (!query || query.length < this.ajax.triggerLength) {
				// cancel the ajax callback if in progress
				if (this.ajax.xhr) {
					this.ajax.xhr.abort();
					this.ajax.xhr = null;
					this.ajaxToggleLoadClass(false);
				}
				return this.shown ? this.hide() : this
			}
	
			function execute() {
				this.ajaxToggleLoadClass(true);
				
				// Cancel last call if already in progress
				if (this.ajax.xhr) this.ajax.xhr.abort();
				
				var params = this.ajax.preDispatch ? this.ajax.preDispatch(query) : { query : query }
				var jAjax = (this.ajax.method == "post") ? $.post : $.get;
				this.ajax.xhr = jAjax(this.ajax.url, params, $.proxy(this.ajaxSource, this));
				this.ajax.timerId = null;
			}
			
			// Query is good to send, set a timer
			this.ajax.timerId = setTimeout($.proxy(execute, this), this.ajax.timeout);
			
			return this;
		}
	
	,	ajaxSource: function (data) {
			this.ajaxToggleLoadClass(false);
	
			var items;
			
			if (!this.ajax.xhr) return;
			
			if (this.ajax.preProcess) {
				data = this.ajax.preProcess(data);
			}
			// Save for selection retreival
			this.ajax.data = data;
			
			items = $.grep(data, function (item) {
				return item;
			})
			
			if (this.sorter!='none') {
				items = this.sorter(items)
			}
			
			if (!items.length) {
				return this.hide()
			}
	
			this.ajax.xhr = null;
			return this.render(items.slice(0, this.options.items)).show()
		}
		
	,	ajaxToggleLoadClass: function (enable) {
			if (!this.ajax.loadingClass) return;
			this.$element.toggleClass(this.ajax.loadingClass, enable);
		}

	,	lookup: function (event) {
			var items
			
			this.query = this.$element.val()
			
			if (!this.query || this.query.length < this.options.minLength) {
				return this.shown ? this.hide() : this
			}
			
			items = $.isFunction(this.source) ? this.source(this.query, $.proxy(this.process, this)) : this.source

			return items ? this.process(items) : this
		}

	,	process: function (items) {
			var that = this

			items = $.grep(items, function (item) {
				return that.matcher(item)
			})
			
			if (this.sorter!='none') {
				items = this.sorter(items)
			}

			if (!items.length) {
				/*return this.shown ? this.hide() : this*/
				return this.hide()
			}
			
			return this.render(items.slice(0, this.options.items)).show()
		}
	
	,	matcher: function (item) {
			return ~item.toLowerCase().indexOf(this.query.toLowerCase())
		}

	,	sorter: function (items) {
			var beginswith = []
				, caseSensitive = []
				, caseInsensitive = []
				, item
			
			while (item = items.shift()) {
				if (this.ajax && this.ajax.displayField)
					item = item[this.ajax.displayField]
				
				if (!item.toLowerCase().indexOf(this.query.toLowerCase())) beginswith.push(item)
				else if (~item.indexOf(this.query)) caseSensitive.push(item)
				else caseInsensitive.push(item)
			}

			return beginswith.concat(caseSensitive, caseInsensitive)
		}

	,	highlighter: function (item) {
			var query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
			return item.replace(new RegExp('(' + query + ')', 'ig'), function ($1, match) {
				return '<strong>' + match + '</strong>'
			})
		}

	,	render: function (items) {
			var that = this;
			
			items = $(items).map(function (i, item) {
				if ( that.ajax && that.ajax.displayField!=null && that.ajax.loadProductPageBy!=null ) {
					
					
					
					
					i = $(that.options.item).attr('data-value', item[ that.ajax.displayField ])
					i.attr('data-id', item[ that.ajax.loadProductPageBy ]);
					i.attr('data-type', item[ that.ajax.dType ]);
					i.find('a').html(that.highlighter(item[ that.ajax.displayField ]))
					
					
					
					
				} else {
					i = $(that.options.item).attr('data-value', item)
					i.find('a').html(that.highlighter(item))
				}
				return i[0]
			})

			items.first().addClass('active')
			this.$menu.html(items)
			
			this.$menu.find("li[data-type='cms']").first().append("<li style='background-color:#D4D4D4;'>&nbsp;Below is contents result:</li>");
			
			return this
		}
		
	,	next: function (event) {
			var active = this.$menu.find('.active').removeClass('active')
				, next = active.next()
			
			if (!next.length) {
				next = $(this.$menu.find('li')[0])
			}
			
			next.addClass('active')
		}

	,	prev: function (event) {
			var active = this.$menu.find('.active').removeClass('active')
				, prev = active.prev()
			
			if (!prev.length) {
				prev = this.$menu.find('li').last()
			}
			
			prev.addClass('active')
		}

	,	listen: function () {
			this.$element
				.on('focus',    $.proxy(this.focus, this))
				.on('blur',     $.proxy(this.blur, this))
				.on('keypress', $.proxy(this.keypress, this))
				.on('keyup',    $.proxy(this.keyup, this))
			
			if (this.eventSupported('keydown')) {
				this.$element.on('keydown', $.proxy(this.keydown, this))
			}
			
			this.$menu
				.on('click', $.proxy(this.click, this))
				.on('mouseenter', 'li', $.proxy(this.mouseenter, this))
				.on('mouseleave', 'li', $.proxy(this.mouseleave, this))
		}

	,	eventSupported: function(eventName) {
			var isSupported = eventName in this.$element
			if (!isSupported) {
				this.$element.setAttribute(eventName, 'return;')
				isSupported = typeof this.$element[eventName] === 'function'
			}
			return isSupported
		}

	,	move: function (e) {
			if (!this.shown) return

			switch(e.keyCode) {
				case 9: // tab
				case 13: // enter
				case 27: // escape
					e.preventDefault()
					break

				case 38: // up arrow
					e.preventDefault()
					this.prev()
					break

				case 40: // down arrow
					e.preventDefault()
					this.next()
					break
			}
			
			e.stopPropagation()
		}

	,	keydown: function (e) {
			this.suppressKeyPressRepeat = ~$.inArray(e.keyCode, [40,38,9,13,27])
			this.move(e)
		}

	,	keypress: function (e) {
			if (this.suppressKeyPressRepeat) return
			this.move(e)
		}

	,	keyup: function (e) {
			switch(e.keyCode) {
				case 40: // down arrow
				case 38: // up arrow
				case 16: // shift
				case 17: // ctrl
				case 18: // alt
					break
				
				case 9: // tab
				case 13: // enter
					if (!this.shown) return
						this.select()
					break
				
				case 27: // escape
					if (!this.shown) return
						this.hide()
					break
				
				default:
					this.ajax ? this.ajaxLookup() : this.lookup()
			}
			
			e.stopPropagation()
			e.preventDefault()
		}
		
	,	focus: function (e) {
			this.focused = true
		}

	,	blur: function (e) {
			this.focused = false
			if (!this.mousedover && this.shown) this.hide()
		}
		
	,	click: function (e) {
			e.stopPropagation()
			e.preventDefault()
			this.select()
			this.$element.focus()
		}

	,	mouseenter: function (e) {
			this.mousedover = true
			this.$menu.find('.active').removeClass('active')
			$(e.currentTarget).addClass('active')
		}
		
	,	mouseleave: function (e) {
			this.mousedover = false
			if (!this.focused && this.shown) this.hide()
		}

	}


	/* TYPEAHEAD PLUGIN DEFINITION
	 * =========================== */

	var old = $.fn.typeahead

	$.fn.typeahead = function (option) {
		return this.each(function () {
			var $this = $(this)
			,	data = $this.data('typeahead')
			,	options = typeof option == 'object' && option
			if (!data) $this.data('typeahead', (data = new Typeahead(this, options)))
			if (typeof option == 'string') data[option]()
		})
	}

	$.fn.typeahead.defaults = {
		source: []
	,	items: 20
	,	menu: '<ul class="typeahead dropdown-menu"></ul>' // 'ul.typeahead.dropdown-menu'
	,	item: '<li><a href="#"></a></li>'
	,	minLength: 1
	}

	$.fn.typeahead.Constructor = Typeahead


	/* TYPEAHEAD NO CONFLICT
	 * =================== */

	$.fn.typeahead.noConflict = function () {
		$.fn.typeahead = old
		return this
	}


	/* TYPEAHEAD DATA-API
	 * ================== */

	$(document).on('focus.typeahead.data-api', '[data-provide="typeahead"]', function (e) {
		var $this = $(this)
		if ($this.data('typeahead')) return
		e.preventDefault()
		$this.typeahead($this.data())
	})

})(window.jQuery);
