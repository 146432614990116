/**
 *
 * Manage zgZoom (and zoom) of product images in a product page
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.product.optionSelected. Option selected
 */


(function ( $, _ ) {
	'use strict';

	var root = this;

	var ZgZoom = (function () {

		// CONSTRUCTOR
		// -------------------------------------------------------------------------

		/**
		 *
		 * @param element
		 * @param options
		 * @constructor
		 */
		function ZgZoom ( element, options ) {
			this._$element = $( element );
			this._$parent  = this._$element.closest( '[data-zg-role="product"]' );
			this._options  = options;
			this._zoomMgr  = null;

			this._initZoom();

			this._setEventHandlers();
		}

		// STATIC METHODS
		// -------------------------------------------------------------------------


		/**
		 *
		 * @param options
		 */
		ZgZoom.setDefaults = function ( options ) {
			if ( options === void 0 ) {
				options = {};
			}

			ZgZoom.DEFAULTS = _.extend( {}, ZgZoom.DEFAULTS, options );
		};


		/**
		 *
		 * @param option
		 * @private
		 */
		ZgZoom._jQueryInterface = function ( option ) {
			return this.each( function () {
				var $this   = $( this );
				var data    = $this.data( 'zg.zoom' );
				var options = $.extend( {}, ZgZoom.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

				if ( !data ) {
					$this.data( 'zg.zoom', (data = new ZgZoom( this, options )) );
				}

				if ( option ) {
					data[option] && data[option]();
				}
			} );
		};


		// PUBLIC METHODS
		// -------------------------------------------------------------------------

		/**
		 *
		 * @private
		 */
		ZgZoom.prototype.hide = function () {
			this._zoomMgr && this._zoomMgr.stop();
		};

		/**
		 *
		 * @private
		 */
		ZgZoom.prototype.show = function () {
			this._zoomMgr && this._zoomMgr.goTo();
		};


		// PRIVATE METHODS
		// -------------------------------------------------------------------------

		/**
		 *
		 * @private
		 */
		ZgZoom.prototype._initZoom = function () {
			if ( root.Threesixty ) {
				this._startZoom();
			} else {
				var pathThreeSixty;
				if( window.DEBUG ){
					pathThreeSixty = 'threeSixty.js';
				}
				else{
					pathThreeSixty = 'threeSixty.min.js';
				}

				$.ajax({
					url: '/themes/' + root.SGL_JS_MERCHANT_ID + '/' + root.SGL_JS_THEME + '/js/lib/' + pathThreeSixty,
					dataType: 'script',
					cache: false,
					success: (this._startZoom).bind( this )
				});
			}
		};

		/**
		 *
		 * @private
		 */
		ZgZoom.prototype._getImages = function ( gallery ) {
			var medium;
			var big;
			var defaultData;

			if ( gallery && gallery[this._options.defaultIndex] ) {
				medium = gallery[this._options.defaultIndex].medium;
				big    = gallery[this._options.defaultIndex].big;
			} else {
				defaultData = this._$parent.data( 'zg.product' ).product;

				if ( defaultData.processedImages && defaultData.processedImages[this._options.defaultIndex] ) {
					medium = defaultData.processedImages[this._options.defaultIndex].medium;
					big    = defaultData.processedImages[this._options.defaultIndex].big;
				} else {
					medium = defaultData.images.medium;
					big    = defaultData.images.big;
				}
			}

			return {
				images:     medium,
				zoomImages: big
			};
		};


		/**
		 *
		 * @private
		 */
		ZgZoom.prototype._startZoom = function () {
			if ( root.Threesixty ) {
				this._zoomMgr = new root.Threesixty( this._$element[0], this._options );
			}
		};

		ZgZoom.prototype._updateAlt = function ( info ) {
			var alt = info.productName || '';

			alt += info.optionName ? (' - ' + info.optionName) : '';

			this._$element.alt = alt;
		};

		ZgZoom.prototype._updateImages = function ( gallery ) {
			var srcs = this._getImages( gallery );

			if ( srcs.images ) {
				if ( this._zoomMgr ) {
					this._zoomMgr.setOptions(
						_.extend( {}, this._options, srcs )
					);
				} else {
					this._$element.attr( 'src', _.isArray( srcs.images ) ? srcs.images[0] : srcs.images );
					this._$element.data( 'zoomImages', _.isArray( srcs.zoomImages ) ? srcs.zoomImages[0] : srcs.zoomImages );
				}

				this._$element.show();
			} else {
				if ( this._zoomMgr ) {
					this._zoomMgr.stop();
				}

				this._$element.hide();
			}
		};


		// EVENT HANDLERS
		// -------------------------------------------------------------------------

		/**
		 * @method _setEventHandlers
		 * @listen document#zg.product.optionSelected. When user select option, if the option has image, render the zgZoom for specific option
		 * @private
		 */
		ZgZoom.prototype._setEventHandlers = function () {
			this._$parent.on( 'zg.product.optionSelected', (function ( e, option ) {
				var gallery = this._options.is360 ? option.gallery360 : option.gallery;

				if ( option.hasImage ) {
					this._updateAlt( option.info || {} );
					this._updateImages( gallery || [] );
				}
			}).bind( this ) );
		};

		return ZgZoom;
	}());


	ZgZoom.DEFAULTS = {
		defaultIndex: 0,
		is360:        false
	};

	/**
	 * @selector data-zg-role="zoom" The plugin start for each selector in the dom when the page load
	 */
	ZgZoom.SELECTOR = '[data-zg-role="zoom"]';

	root.ZgZoom = ZgZoom;

	// ZOOM PLUGIN DEFINITION
	// =========================

	$.fn.zgZoom             = ZgZoom._jQueryInterface;
	$.fn.zgZoom.Constructor = ZgZoom;

	// =========================

	function initZgZoom ( option ) {
		ZgZoom._jQueryInterface.call( $( ZgZoom.SELECTOR ), option );
	}

	// =========================

	$(document).on( 'zg.product.ready', function () {
		initZgZoom();
	} );
	$(document).on( 'zg.gallery.rendered', function () {
		initZgZoom();
	} );

	$( function () {
		initZgZoom();

		// =========================

		var $modal = $( '.modal' );

		// stop outside zooms
		$modal.on( 'show.bs.modal.zg.Zoom', function () {
			initZgZoom( 'hide' );
		} );

		// start inside zooms
		$modal.on( 'shown.bs.modal.zg.Zoom', function () {
			$( this ).find( ZgZoom.SELECTOR ).each( function () {
				ZgZoom._jQueryInterface.call( $( this ), 'show' );
			} );
		} );

		// stop inside zooms
		$modal.on( 'hide.bs.modal.zg.Zoom', function () {
			$( this ).find( ZgZoom.SELECTOR ).each( function () {
				ZgZoom._jQueryInterface.call( $( this ), 'hide' );
			} );
		} );

		// start outside zooms
		$modal.on( 'hidden.bs.modal.zg.Zoom', function () {
			initZgZoom( 'show' );
		} );
	} );

}).call( this, jQuery, _ );
