// add your site specific javascript here
/*STORIES MANAGER*/
if($('#stories').length!=0){
	var selector = $('#stories');
	//var stories = selector.find('.story');
	var stories = selector.find('[data-role="story"]');
	var paging = $('#stories').data('paging');

	function showStories(){
		var count=0;
		
		$.each(stories, function(  ) {
			if(count<paging){
				if($(this).hasClass('cover')){
					$(this).removeClass('cover');
					count++;
				}
			}
		});
		count=0;
		$('#pagination').addClass('hidden');
		if(selector.find('.cover').length!=0){
			$('#pagination').removeClass('hidden');
		}
	}
	showStories();
	$('#pagination').on('click',function(){
		showStories();
	});
	if($('#banners [data-row]').length!=0){
		console.log("start banner");
		var selector = $('#banners');
		var banners = selector.find('[data-row]');
		$.each(banners, function(  ) {
			console.log("banner");
			var row=$(this).data('row') || -1;
			row=(row*3)-1;
			
			if(row != -1){
				var l=$('#stories .story').length-1;
				console.log(row);
				if(l<row){
					row=l;
				}
				//console.log(l);
				$('#stories').find('.story:eq('+row+')').after( $( this ) );
			}
		});
	}
}

