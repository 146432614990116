/**
 *
 * Plugin for injecting captcha when a form is submitted
 *
 * @author: Pablo Maceda <pmaceda[at]kooomo[dot]com>
 *
 */

(function ($) {
    'use strict';

    /**
     * @selector data-zg-captcha="true" The plugin start if there is the selector in the dom when the page load
     */
    var selector = '[data-zg-captcha="true"]';


    // CAPTCHA CLASS DEFINITION
    // =============================

    /**
     *
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var Captcha = function (element, options) {
        this.$element = $(element);
        this.options = options;

        //this.$target = $(this.options.target);
        this.__setEventHandlers();
    };


    /**
     * @param {string} [activeClass] Class for the loading form
     * @param {string} [version] Version used for backend validation
     */
    Captcha.DEFAULTS = {
        captcha:            CAPTCHA,
        version:     CAPTCHA_VERSION
    };


    /**
     *
     * @private
     */
    Captcha.prototype.__setEventHandlers = function () {

        this.$element.find(':submit').on('click', (function (e) {
					
						console.log('submit');
            if (this.success) return;

            e.preventDefault();

            var $form = this.$element;
            //$form.addClass(this.options.loadingClass);

            //$('<div class="loading btn" style="min-height:auto;height:80px;width:80px;"></div>').insertAfter(this.$element.find(':submit'));


            // Render captcha if the widget doesn't exist
            if (!this.widgetId) {
                var captchaContainer = document.createElement('div');
                $form.append(captchaContainer);
                $form.append('<input type="hidden" name="zg_captcha_version" value="' + this.options.version + '"/>');

                this.widgetId = this.__render(
                    captchaContainer,
                    function () {
                        this.success = true;
                        //$form.removeClass(this.options.loadingClass);
                        $form.submit();
                        this.$element.find(':submit').prop('disabled', false);
                        this.$element.find(':submit').next().remove();
                    }.bind(this),
                    function () {
                        this.success = false;
                        //$form.removeClass(this.options.loadingClass);
                        this.$element.find(':submit').prop('disabled', false);
                        this.$element.find(':submit').next().remove();
                    }.bind(this)
                );
            }
            /* disable for 2 sec */
            var $submit=$form.find(':submit');
            $submit.prop('disabled', true);
            var text= $submit.html();
            $submit.html('<i class="fa fa-circle-o-notch fa-spin"></i> &nbsp;' + text);
            setTimeout(function() {
                $submit.prop('disabled', false);
                $submit.html(text);
            }, 2000);

            grecaptcha.execute(this.widgetId);

        }).bind(this));
    };


    /**
     *
     * @private
     */
    Captcha.prototype.__render = function (container, onSuccess, onError) {

        console.log(this.options.captcha);

        return grecaptcha.render(container, {
            //'sitekey': '6Ld3vjEUAAAAAPSsKJuGiV_lHeZPlsTzrSTYaXyS', // TODO
            'sitekey': this.options.captcha,
            'size': 'invisible',
            'callback': onSuccess,
            'error-callback': onError,
            'expired-callback': onError
        });
    };


    /**
     *
     * @param {Object} options
     * @private
     */
    Captcha.prototype.__setOptions = function (options) {
        _.extendOwn(this.options, options || {});
    };


    // CAPTCHA PLUGIN DEFINITION
    // =================================

    function Plugin(option) {
        return this.each(function () {
            var $this = $(this);

            if (!$this.data('zg.captcha')) {
                var options = _.extend({}, Captcha.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option);

                $this.data('zg.captcha', (new Captcha(this, options)));
            }
        });
    }

    $.fn.zgCaptcha = Plugin;
    $.fn.zgCaptcha.Constructor = Captcha;


    // CAPTCHA DATA-API
    // ========================

    $(function () {
        var $selector = $(selector);

        if ($selector.length) {
            (function (d, s, id, src) {
                var fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                var js = d.createElement(s);
                js.id = id;
                js.src = src;
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'google-captcha-sdk', 'https://www.google.com/recaptcha/api.js?render=explicit&hl=en')); // TODO lang
        }

        $selector.each(function () {
            Plugin.call($(this));
        });
    });

}(jQuery));
