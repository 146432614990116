function validateNumber(event) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else {
        return true;
    }
};

$( '[data-zg-role="birth_date"]' ).keypress(validateNumber);

$( document ).on( 'change', '[data-zg-role="birth_date"]', function () {
	var $this = $(this);
	var day = $this.closest('form').find('.txt_day').val();
	var month = $this.closest('form').find('.txt_month').val();
	var year =  $this.closest('form').find('.txt_year').val();
	if(day && day.length == 1) {
		day = "0" + day;
		 $this.closest('form').find('.txt_day').val(day);
	};
	if(month && month.length == 1){
		month = "0" + month;
		 $this.closest('form').find('.txt_month').val(month);
	}
	var date = year + '-' + month + '-' + day;
	$this.closest('form').find('[data-zg-role="birth_date_hidden"]').val( date );
	console.log(date);
} );
			