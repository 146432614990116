// Mobile panel - Elastic Search
$(document).on('click','[data-zg-role="action-panel"]',function(e){
	e.preventDefault();
	var id = $( this ).data('action');
	actionPanel( id );
    if( $('body').hasClass('stop-scrolling')){
        $('body').removeClass('stop-scrolling');
    }else{
        $('body').addClass('stop-scrolling');
    }
});

/* Set the width of the side navigation to 250px */
function actionPanel(id) {
    closeAllPanel();
	$('#'+id).toggleClass("open");
}

function closeAllPanel() {
    // menu 
    if( $('.mobile-toggle').hasClass('is-open')){
        $('#navbar-collapse-main').collapse('toggle');
        $('.mobile-toggle').removeClass('is-open');
    }
}